import './loader.css';
import pAdSwD1fP4 from './../../img/pAdSwD1fP4.gif';

const Loader = () => {
    return (
        <div className="flex p-10 justify-center">
            <img src={ pAdSwD1fP4 } alt=""/>
        </div>
    );
};

export default Loader;
